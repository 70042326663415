<template>
  <div>
    <div class="page-title">
      <h3>Batch Update</h3>
      <div class="page-breadcrumb">
        <ol class="breadcrumb">
          <li>
            <router-link
              :to="{name: 'home'}"
            >
              Home
            </router-link>
          </li>
          <li>
            <router-link
              :to="{name: 'dashboard'}"
            >
              Dashboard
            </router-link>
          </li>
          <li class="active">
            Batch Update
          </li>
        </ol>
      </div>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-md-6">
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h4 class="panel-title">
                CSV Upload
              </h4>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="col-md-12">
                  <div
                    id="csvUpload"
                    class="dropzone"
                  >
                    <!-- Not displayed, just for Dropzone's `dictDefaultMessage` option -->
                    <div
                      id="dropzone-message"
                      style="display: none"
                    >
                      <span class="dropzone-title">Drop your file here or click to select</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-t-lg">
                <div class="col-md-2">
                  <button
                    id="uploadFile"
                    class="btn btn-default btn-info"
                    type="button"
                    @click.stop.prevent="uploadFile"
                  >
                    Update Forms
                  </button>
                </div>
              </div>
              <div class="row m-t-sm">
                <div
                  class="col-md-12"
                  style="font-size: 14px; font-weight: 600"
                >
                  (<span :class="uploadStatus.css">{{ uploadStatus.text }}</span>,
                  Updated: <span
                    id="success-count"
                    class="text-success"
                  >{{ successCount }}</span>,
                  Not Updated: <span
                    id="error-count"
                    class="text-danger"
                  >{{ errorCount }}</span>)
                </div>
              </div>
              <div
                v-if="errorText !== ''"
                class="row"
              >
                <div class="col-md-12">
                  <span
                    id="error"
                    class="text-danger"
                  >{{ errorText }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h4 class="panel-title">
                Forms Not Updated
              </h4>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  id="submittedFormsList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr>
                      <th>Submission Date</th>
                      <th>Submission Time</th>
                      <th>Bank Verification Date</th>
                      <th>Resident City</th>
                      <th>Owner</th>
                      <th>Supervisor</th>
                      <th>Branch Manager</th>
                      <th>Status</th>
                      <th>Reference Number</th>
                      <th>Remarks</th>
                      <th>Offer Type</th>
                      <th>Product</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Pancard</th>
                      <th>Email</th>
                      <th>Mobile Number</th>
                      <th>Last Updated By</th>
                      <th>Updated On</th>
                    </tr>
                  </thead>
                </table>
                <AIPFormModal
                  :form-details="formDetails"
                  :job-type="jobType"
                  :report-only="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import Dropzone from "dropzone";
import {uploadStatuses} from "@/constants";
import AIPFormModal from "../../components/AIPFormModal";
import Papa from "papaparse";

export default {
  name: "Upload",
  components: {
    AIPFormModal
  },
  data() {
    return {
      jobType: '',
      formsTable: null,
      tableData: [],
      formDetails: {},
      fileUploader: null,
      successCount: 0,
      errorCount: 0,
      uploadStatus: uploadStatuses.success,
      errorText: ''
    }
  },
  beforeRouteLeave(to, from, next) {
    let formModal = $("#formDetailsModal");
    if ((formModal.data('bs.modal') || {}).isShown) {
      formModal.modal('hide');
      document.getElementById('submittedFormsList').scrollIntoView({behavior: 'instant'});
      next(false);
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters([
      "getLoggedInUser",
      "getStatuses",
      "getTitles",
      "getProducts",
      "getUsers",
      "getForms"
    ]),
    ...mapState([
      'loggedInUser'
    ])
  },
  watch: {
    loggedInUser(newValue) {
      if (newValue && newValue !== '') {
        if (newValue.jobTitle && newValue.jobTitle !== '') {
          let jobTitle = _.find(this.getTitles, (title) => {
            return title.id === newValue.jobTitle;
          });
          if (jobTitle) {
            this.jobType = jobTitle.access;
          }
        }
      }
    }
  },
  created() {
    Dropzone.autoDiscover = false;
  },
  mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');

    let options = {
      url: '/',
      method: 'put',
      maxFiles: 1,
      maxfilesexceeded: function (file) {
        this.removeAllFiles();
        this.addFile(file);
      },
      acceptedFiles: ".csv",
      capture: 'file',
      addRemoveLinks: true,
      parallelUploads: 1,
      uploadMultiple: false,
      header: '',
      dictDefaultMessage: document.querySelector('#dropzone-message').innerHTML,
      autoProcessQueue: false
    };

    // Instantiate DropZone
    this.fileUploader = new Dropzone("div#csvUpload", options);

    if (this.getLoggedInUser && this.getLoggedInUser !== '') {
      if (this.getLoggedInUser.jobTitle && this.getLoggedInUser.jobTitle !== '') {
        let jobTitle = _.find(this.getTitles, (title) => {
          return title.id === this.getLoggedInUser.jobTitle;
        });
        if (jobTitle) {
          this.jobType = jobTitle.access;
        }
      }
    }

    this.createDatatable();
  },
  methods: {
    createDatatable: function () {
      let buttons = [
        {
          extend: 'pageLength',
          text: '<i class="fas fa-filter"></i>',
          titleAttr: 'Number of Rows'
        },
        {
          extend: 'colvis',
          text: '<i class="fas fa-eye"></i>',
          titleAttr: 'Column Visibility',
          columns: ':not(.noVis)'
        }
      ];
      if (this.jobType === 'BackOffice') {
        buttons.push(
            {
              extend: 'excelHtml5',
              autoFilter: true,
              text: '<i class="far fa-file-excel"></i>',
              titleAttr: 'Excel',
              exportOptions: {
                columns: [0, 2, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14]
              }
            }
        );
      } else if (this.jobType === 'Owner') {
        buttons.push(
            {
              extend: 'excelHtml5',
              autoFilter: true,
              text: '<i class="far fa-file-excel"></i>',
              titleAttr: 'Excel'
            }
        );
      }
      this.formsTable = $('#submittedFormsList').DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.tableData,
        rowId: 'id',
        columns: [
          {
            data: 'createdAt'
          },
          {
            data: 'createdAt'
          },
          {
            data: 'bankVerificationDate',
            defaultContent: ""
          },
          {
            data: 'city',
            defaultContent: ""
          },
          {
            data: 'owner',
            defaultContent: ""
          },
          {
            data: 'supervisor',
            defaultContent: ""
          },
          {
            data: 'branchManager',
            defaultContent: ""
          },
          {
            data: 'status',
            defaultContent: ""
          },
          {
            data: 'referenceNumber',
            defaultContent: ""
          },
          {
            data: 'remarks',
            defaultContent: ""
          },
          {
            data: 'offerType',
            defaultContent: ""
          },
          {
            data: 'product',
            defaultContent: ""
          },
          {
            data: 'firstName'
          },
          {
            data: 'lastName'
          },
          {
            data: 'pancard'
          },
          {
            data: 'email',
            defaultContent: ""
          },
          {
            data: 'mobileNumber',
            defaultContent: ""
          },
          {
            data: 'updatedBy',
            defaultContent: ""
          },
          {
            data: 'updatedAt',
            defaultContent: ""
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('DD-MM-YYYY');
              } else if (!data || data === '') {
                return '';
              } else {
                return moment(data, "YYYY-MM-DD").format('DD-MM-YYYY');
              }
            },
            targets: [0, 2]
          },
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('h:mm A');
              } else {
                return '';
              }
            },
            targets: 1
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'User');
            },
            targets: [4, 5, 6, 17]
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Status');
            },
            targets: 7
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Product');
            },
            targets: 11
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Date');
            },
            targets: 18
          },
          {
            visible: false,
            targets: [8, 9, 10, 11, 15, 16]
          }
        ],
        buttons: buttons,
        search: {
          regex: true,
          smart: false
        },
        order: [[0, "desc"], [1, "desc"]]
      });

      this.formsTable.buttons()
          .container()
          .appendTo('#submittedFormsList_wrapper .col-sm-6:eq(0)');

      this.setupFormModal();
    },
    prettyPrint: function (value, type) {
      if (type === 'Date') {
        if (_.includes(value, 'T')) {
          return moment(value).format('MMMM Do YYYY h:mm A');
        } else if (!value || value === '') {
          return '';
        } else {
          return moment(value, "YYYY-MM-DD").format('DD-MM-YYYY');
        }
      } else if (type === 'Status') {
        return _.result(_.find(this.getStatuses, (status) => {
          return status.id === value;
        }), 'name') || '';
      } else if (type === 'Product') {
        return _.result(_.find(this.getProducts, (product) => {
          return product.id === value;
        }), 'name') || '';
      } else if (type === 'User') {
        let user = _.find(this.getUsers, (user) => {
          return user.username === value;
        });
        if (user) {
          return _.upperCase(user.firstName + ' ' + user.lastName);
        } else {
          return value;
        }
      }
    },
    setupFormModal: function () {
      $('#submittedFormsList tbody').on('click', 'tr', (e) => {
        this.formDetails = this.formsTable.row(e.currentTarget).data();
        if (this.formDetails) {
          $("#formDetailsModal").modal('show');
        } else {
          this.formDetails = {};
        }
      });
    },
    uploadFile: async function () {
      try {
        const currentTimeEpoch = moment().format('X');
        let files = this.fileUploader.getQueuedFiles();
        if (files && files.length > 0) {
          this.formsTable.clear().draw();
          this.successCount = 0;
          this.errorCount = 0;
          this.errorText = '';
          this.tableData = _.cloneDeep(this.getForms);
          this.uploadStatus = uploadStatuses.progress;
          let updateCounter = 0;
          let updatePromises = [];

          let csv = files[0];
          this.fileUploader.removeAllFiles();
          Papa.parse(csv, {
            header: true,
            dynamicTyping: true,
            worker: true,
            step: (results) => {
              if (results.data && results.data['Apps No'] && results.data['Apps No'] !== '') {
                let appId = _.trim(_.split(results.data['Apps No'], '-')[0]);
                let oldForm = _.find(this.tableData, (form) => {
                  return form.appId === appId;
                });
                if (oldForm) {
                  if (results.data['WIP STATUS'] && results.data['WIP STATUS'] !== '') {
                    let bankStatus = results.data['WIP STATUS'];
                    let systemStatus;
                    if (_.startsWith(bankStatus, 'WIP')) {
                      systemStatus = _.find(this.getStatuses, (status) => {
                        return status.name === 'WIP';
                      });
                    } else if (bankStatus === 'Approved') {
                      systemStatus = _.find(this.getStatuses, (status) => {
                        return status.id === '01f91740-1c7a-4c71-b14e-c1f3e8eb34e0';  //CARD OUT STATUS ID
                      });
                    } else {
                      systemStatus = _.find(this.getStatuses, (status) => {
                        return _.lowerCase(status.name) === _.lowerCase(bankStatus);
                      });
                    }
                    if (systemStatus) {
                      updateCounter++;
                      _.remove(this.tableData, (form) => {
                        return form.id === oldForm.id;
                      });
                      let newForm = {
                        id: oldForm.id,
                        status: systemStatus.id,
                        remarks: '',
                        updatedBy: this.getLoggedInUser.username,
                        updatedAtEpoch: currentTimeEpoch,
                        expectedVersion: oldForm.version
                      };
                      if (!oldForm.bankReceivedDate || oldForm.bankReceivedDate === '') {
                        let applicationReceivedDate = results.data["Application Receipt date"];
                        if (applicationReceivedDate && applicationReceivedDate !== '') {
                          newForm.bankReceivedDate = moment(applicationReceivedDate, 'D-MMM-YY').format('YYYY-MM-DD');
                        }
                      }
                      if (systemStatus.name === 'WIP') {
                        newForm.remarks = _.trim(bankStatus.substr(3, bankStatus.length), ' _-:')
                      } else if (systemStatus.name === 'DECLINED') {
                        let isFinalDeclined = false;
                        for (let index = 1; index < 4; index++) {
                          if (results.data['Declined Reason ' + index] && results.data['Declined Reason ' + index] !== '') {
                            let reasonCheck = _.lowerCase(results.data['Declined Reason ' + index]);
                            if (!_.includes(['rmtvr', 'rnoi-tvr', 'rnoi-vr', 'roer', 'rout', 'not'], reasonCheck)) {
                              isFinalDeclined = true;
                            }
                            let reason = _.trim(results.data['Declined Reason ' + index]);
                            if (newForm.remarks !== '') {
                              newForm.remarks += ', ' + reason;
                            } else {
                              newForm.remarks = reason;
                            }
                          }
                        }
                        let declinedDate = results.data['Date'];
                        if (declinedDate && declinedDate !== '') {
                          newForm.bankResultDate = moment(declinedDate, 'D-MMM-YY').format('YYYY-MM-DD');
                        }
                        if (isFinalDeclined) {
                          newForm.status = '827a8a72-3bbd-4d92-a272-b440b1f895dc';        //FINAL DECLINED STATUS ID
                        }
                      } else if (systemStatus.name === 'INCURABLE') {
                        for (let index = 1; index < 13; index++) {
                          if (results.data['Discrepant reason ' + index] && results.data['Discrepant reason ' + index] !== '') {
                            let reason = _.trim(results.data['Discrepant reason ' + index]);
                            if (newForm.remarks !== '') {
                              newForm.remarks += ', ' + reason;
                            } else {
                              newForm.remarks = reason;
                            }
                          }
                        }
                        if (results.data['FCU Discrepant  reason 11 - Mob'] && results.data['FCU Discrepant  reason 11 - Mob'] !== '') {
                          let reason = results.data['FCU Discrepant  reason 11 - Mob'];
                          if (newForm.remarks !== '') {
                            newForm.remarks += ', ' + reason;
                          } else {
                            newForm.remarks = reason;
                          }
                        }
                      } else if (systemStatus.id === '01f91740-1c7a-4c71-b14e-c1f3e8eb34e0') {            //CARD OUT STATUS
                        let cardOutDate = results.data['Date'];
                        if (cardOutDate && cardOutDate !== '') {
                          newForm.bankResultDate = moment(cardOutDate, 'D-MMM-YY').format('YYYY-MM-DD');
                        }
                      }
                      updatePromises.push(this.updateForm(newForm));
                    }
                  }
                }
              }
            },
            complete: () => {
              Promise.all(updatePromises).then(() => {
                this.errorCount = this.tableData.length;
                if (this.errorCount > 0) {
                  this.uploadStatus = uploadStatuses.warning;
                  this.formsTable.clear().draw();
                  this.formsTable.rows.add(this.tableData);
                  this.formsTable.columns.adjust().draw();
                } else {
                  this.uploadStatus = uploadStatuses.success;
                }
                this.successCount = updateCounter;
              });
            },
            error: (err) => {
              this.uploadStatus = uploadStatuses.error;
              this.errorText = err;
            }
          })

        }
      } catch (err) {
        this.uploadStatus = uploadStatuses.error;
        this.errorText = err;
      }
    },
    async updateForm(form) {
      try {
        const {
          data: updateForm
        } = await API.graphql(graphqlOperation(mutations.updateForm, {
          input: _.omitBy(form, (field) => {
            return (!field) || field === '';
          })
        }));

        let oldForms = _.cloneDeep(this.getForms);
        let formIndex = _.findIndex(oldForms, (oldForm) => {
          return oldForm.id === updateForm.id;
        });
        if (formIndex !== -1) {
          oldForms[formIndex] = updateForm;
          this.UPDATE_FORMS(_.uniqBy(oldForms, 'id'));
          this.UPDATE_UPDATE_SUBSCRIPTION_FORM(updateForm);
        }
      } catch (e) {
        //TODO: what to do if form is found in csv but the update fails?
      }
    }
  }
}
</script>

<style scoped>

</style>
